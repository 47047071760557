import type {
  AppManifest,
  WidgetInstallationType,
} from '@wix/platform-editor-sdk';
import { EditorReadyFn } from '@wix/yoshi-flow-editor';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { PanelsApiFactory } from '@wix/blocks-widget-services/panels';
import {
  BOOK_BUTTON_DESIGN_PANEL,
  BOOK_BUTTON_DEV_CENTER_WIDGET_ID,
  BOOK_BUTTON_ROLE,
  BOOK_BUTTON_WIDGET_ID,
  PANEL_NAME,
  PLATFORM,
} from './components/consts';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowAPI,
) => {
  const customPanelOptions = {
    editorSDK,
    editorType: options.origin.type,
  };
  const panelsApi = await new PanelsApiFactory().createPanelsApi(
    customPanelOptions,
  );
  await editorSDK.addEventListener('componentDeleted', async (event) => {
    const componentRef = event.detail.componentRef;

    const ancestors = await editorSDK.components.getAncestors('', {
      componentRef,
    });

    const allAppRefComponents =
      await editorSDK.document.components.refComponents.getAllAppRefComponents(
        'token',
      );

    if (
      ancestors.length === 0 &&
      allAppRefComponents.length === 0 &&
      componentRef.type !== PLATFORM.MOBILE
    ) {
      await editorSDK.application.uninstall('', { openConfirmation: false });
    }
  });
  await editorSDK.addEventListener('widgetGfppClicked', async (event) => {
    const { id, componentRef } = event.detail;
    switch (id) {
      case BOOK_BUTTON_DESIGN_PANEL:
        void panelsApi.openBlocksPanel(PANEL_NAME, componentRef);
        break;
      default:
        break;
    }
  });
  if (
    options.firstInstall &&
    (options.origin.info?.type === 'APP_MARKET' ||
      options.origin.info?.type === 'IMPORT_PANEL' ||
      options.origin.info?.type === 'SILENT_INSTALL' ||
      options.origin.type === 'RESPONSIVE')
  ) {
    await editorSDK.application.appStudioWidgets.addWidget('', {
      widgetId: BOOK_BUTTON_DEV_CENTER_WIDGET_ID,
      installationType: 'closed' as WidgetInstallationType,
      layout: {
        height: 100,
        width: 148,
        x: 0,
        y: 100,
      },
      presetIds: {
        style: 'variants-lgnn37aw',
        layout: 'variants-lgnn37aw',
      },
      layouts: {
        componentLayout: {
          minHeight: {
            type: 'auto',
          },
          hidden: false,
          height: {
            type: 'auto',
          },
          type: 'ComponentLayout',
          width: {
            type: 'px',
            value: 148,
          },
        },
        itemLayout: {
          id: '',
          alignSelf: 'start',
          margins: {
            left: {
              type: 'px',
              value: 0,
            },
            right: {
              type: 'px',
              value: 0,
            },
            top: {
              type: 'percentage',
              value: 10,
            },
            bottom: {
              type: 'px',
              value: 0,
            },
          },
          gridArea: {
            rowStart: 1,
            rowEnd: 2,
            columnStart: 1,
            columnEnd: 2,
          },
          justifySelf: 'center',
          type: 'GridItemLayout',
        },
      },
    });
  }
};

export const getAppManifest = async (
  options,
  editorSDK,
  contextParams,
  flowAPI,
): Promise<AppManifest> => {
  const t = flowAPI.translations.t;
  const { appManifestBuilder } = options;
  const targetRole = BOOK_BUTTON_ROLE;

  appManifestBuilder.configureWidget(BOOK_BUTTON_WIDGET_ID, (widgetBuilder) => {
    widgetBuilder
      .gfpp()
      .set('mainAction1', {
        label: t('book-button.settings.manage-service'),
        onClick: () => {
          editorSDK.editor.openDashboardPanel('', {
            url: '/bookings/services',
            closeOtherPanels: false,
          });
        },
      })
      .set('settings', {
        target: {
          role: targetRole,
        },
      })
      .set('connect', { behavior: 'HIDE' })
      .set('layout', {
        target: {
          role: targetRole,
        },
      })
      .set('design', {
        target: {
          role: targetRole,
        },
      })
      .set('link', {
        target: {
          role: targetRole,
        },
      })
      .set('help', {
        id: 'dcd84888-5c8f-4845-a631-d12fb34b8421',
      });
  });
  const baseManifest = await fetchWidgetsStageData(options);
  return appManifestBuilder.withJsonManifest(baseManifest).build();
};
