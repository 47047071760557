export const BOOK_BUTTON_DESIGN_PANEL = 'buttonDesignPanel';
export const PANEL_NAME = 'Desgin Panel';
export const BOOK_BUTTON_DEV_CENTER_WIDGET_ID =
  '0200c225-f6d7-4e5d-95b6-e3e25f05e9c8';

export const BOOK_BUTTON_ROLE = 'button1';
export const BOOK_BUTTON_WIDGET_ID =
  'c9f8a234-71c8-4db3-a7cd-0d26c06faaeb-x5kmw';

export enum PLATFORM {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
}
