import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { BOOK_BUTTON_ROLE } from '../consts';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder) => {
  widgetBuilder.set({
    displayName: 'Book Button',
    nickname: 'BookButton',
  });
  widgetBuilder.configureConnectedComponents(
    BOOK_BUTTON_ROLE,
    (componentBuilder) => {
      componentBuilder.gfpp().set('connect', { behavior: 'HIDE' }).set('help', {
        id: 'dcd84888-5c8f-4845-a631-d12fb34b8421',
      });
    },
  );
};
